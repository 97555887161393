import { PetTypes } from "@/types/emr";
import { useStorage } from "@vueuse/core";

const { isRegisterTest } = useEvolvRegisterTest();

interface CustomerAddress {
  province: string;
  province_code: string;
}

interface Breed {
  id: number | null;
  name: string;
}

export interface State {
  email: string;
  customer_address: CustomerAddress;
  pet_name: string;
  pet_type: PetTypes;
  dob: string;
  pet_gender: "Male" | "Female";
  breed: Breed;
  visit_reasons: string[];
  selected_plan_id: number | string | null;
  pet_number?: number | null;
}

const initialState: State = {
  email: "",
  customer_address: {
    province: "",
    province_code: "",
  },
  pet_name: "",
  pet_type: "" as PetTypes,
  dob: "",
  pet_gender: "Male",
  breed: {
    id: null,
    name: "",
  },
  visit_reasons: [],
  selected_plan_id: null,
  ...(isRegisterTest.value ? { pet_number: null } : {}),
};

const state = useStorage<State>("dp:register", initialState, localStorage, {
  mergeDefaults: true,
});

export default () => state;
